export default class CategoriesEnum {

    constructor(items) {
        this.slugMap = new Map();
        this.idMap = new Map();
        this.items = items;

        this.categoryNullObject = {
            id: null,
            name: '',
            slug: 'null',
            parent: null,
            is_active: true,
            children: []
        }

        if (Array.isArray(items)) {
            for (var i=0; i<items.length; i++) {
                this.idMap.set(items[i].id, i);
                //todo slug je povinny?
                this.slugMap.set(items[i].slug, i);
            }
        }
    }

    getById(id) {
        var index = this.idMap.get(id);
        return index !== undefined ? this.items[index] : this.categoryNullObject;
    }

    getBySlug(slug) {
        var index = this.slugMap.get(slug);
        return index !== undefined ? this.items[index] : this.categoryNullObject;
    }

    toArray() {
        return this.items;
    }

};
